<template>
  <div>
    
    <Menu />

    <section v-if="!loading">

    <div style="border-top-left-radius: 10px; border-top-right-radius:10px; z-index: 999; position: fixed; bottom: 0; left: 50%; transform: translateX(-50%); padding: 10px; background-color: #efefef;" class="text-center">
      <div class="container">
        <div class="d-flex justify-content-center">
          <div class="d-flex align-items-center me-4">
            <i class="bi bi-whatsapp display-5 text-danger me-2"></i>
            <a v-if="this.agent.first_name != null" target="_blank" class="h5 text-decoration-none text-danger" :href="whatsappLink">
              <strong>{{ agent.first_name }}</strong>
            </a>
          </div>
          <div class="d-flex align-items-center">
            <i class="bi bi-whatsapp display-5 text-danger me-2"></i>
            <a v-if="this.agent_2.first_name != null" target="_blank" class="h5 text-decoration-none text-danger" :href="whatsappLink_2">
              <strong>{{ agent_2.first_name }}</strong>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="banner" class="mt-5" v-if="!loading">
      <div class="container-fluid g-0">
        <div class="row justify-content-center g-0">
          <div class="col-12">
            <div class="porfolio-banner">
              <img :src="this.ogListing[0].listing_images[0]" style="width: 100%;" />
              <h1 class="text-md-start text-center p-4" style="text-transform: uppercase;"><strong>{{ this.ogListing[0].property_type }}</strong>&nbsp; {{ ogListing[0].loc_suburb }}, {{ ogListing[0].loc_region }}<br/> {{ formattedPrice }}</h1>
            </div>
          </div>
        </div>
      </div>  
    </div>

    <div class="container-fluid bg-light">

      <div class="row">
        <div class="col-12 text-center">
          <h2 class="pt-5"><strong>CONTACT DETAILS</strong></h2>
        </div>
      </div>
      
      <div class="row justify-content-center pt-5 pb-5">
        
        <div class="col-md-4 text-center" v-if="this.agent.agent_photo">
          <div class="container">
            <div class="row pt-4">
              <div class="col-md-5">
                <img :src="this.agent.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
              </div>
              <div class="col-md-7 text-center">
                <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent.first_name }} {{ agent.last_name }}</strong></h3>
                <div v-if="!contactRequested">
                  <center><button class="btn btn-primary filter-btn" v-on:click="contactRequested = true">Details</button></center>
                </div>
                <div v-if="contactRequested">
                  <p style="line-height: 26px;"><a style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent.cell_number }}</a><br/>
                  <a style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent.email_address"><i class="bi bi-envelope-fill"></i> {{ agent.email_address }}</a><br/>
                  <a style="text-decoration: none;" class="switch-blue" :href="whatsappLink" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 text-center" v-if="this.agent_2.agent_photo">
          <div class="container">
            <div class="row pt-4">
              <div class="col-md-5">
                <img :src="this.agent_2.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
              </div>
              <div class="col-md-7 text-center">
                <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_2.first_name }} {{ agent_2.last_name }}</strong></h3>
                <div v-if="!contactRequested_2">
                  <center><button class="btn btn-primary filter-btn" v-on:click="contactRequested_2 = true">Details</button></center>
                </div>
                <div v-if="contactRequested_2">
                  <p style="line-height: 26px;"><a style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_2.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_2.cell_number }}</a><br/>
                  <a style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_2.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_2.email_address }}</a><br/>
                  <a style="text-decoration: none;" class="switch-blue" :href="whatsappLink_2" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 text-center" v-if="this.agent_3.agent_photo">
          <div class="container">
            <div class="row pt-4">
              <div class="col-md-5">
                <img :src="this.agent_3.agent_photo" style="border-radius: 30px;" class="img img-fluid" />
              </div>
              <div class="col-md-7 text-center">
                <h3 class="switch-blue mb-3 mt-4 text-center"><strong>{{ agent_3.first_name }} {{ agent_3.last_name }}</strong></h3>
                <div v-if="!contactRequested_3">
                  <center><button class="btn btn-primary filter-btn" v-on:click="contactRequested_3 = true">Details</button></center>
                </div>
                <div v-if="contactRequested_3">
                  <p style="line-height: 26px;"><a style="text-decoration: none;" class="switch-blue" :href="'tel:' + agent_3.cell_number"><i class="bi bi-telephone-fill"></i> {{ agent_3.cell_number }}</a><br/>
                  <a style="text-decoration: none;" class="switch-blue" :href="'mailto:' + agent_3.email_address"><i class="bi bi-envelope-fill"></i> {{ agent_3.email_address }}</a><br/>
                  <a style="text-decoration: none;" class="switch-blue" :href="whatsappLink_3" target="_blank"><i class="bi bi-whatsapp"></i> Whatsapp</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="wrapper">
      <div class="container-fluid">
        <div class="row mt-5">
          <div class="col-12 align-self-center my-4">
            <iframe
              width="100%"
              height="515"
              v-if="ogListing[0].video_id !== null"
              :src="'https://www.youtube.com/embed/' + ogListing[0].video_id + '?rel=0&modestbranding=1'"
              frameborder="0"
              allowfullscreen
            ></iframe>

            <div class="position-relative text-center mt-5">
              <p><strong>Gallery</strong></p>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-6" v-for="(item, index) in this.ogListing[0].listing_images">
                      <img :src="item" class="img-fluid py-2" />
                  </div>
                </div>
              </div>
    
              <!-- Left Button -->
              <!-- <button class="btn btn-primary bg-white filter-btn position-absolute top-50 translate-middle-y start-0 ms-3" data-bs-target="#carouselTemplateThree" data-bs-slide="prev" style="z-index: 10;">
                <i class="bi bi-arrow-left"></i>
              </button> -->
              
              <!-- Carousel -->
              <!-- <div class="property-overview-block property-overview-block3 mt-1" id="carousel-toggle">
                <div id="carouselTemplateThree" class="carousel slide temp2">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="row justify-content-center">
                        <div class="col-12 col-md-12 text-center">
                          <button type="button" class="carousel-zoom">
                            <img :src="this.ogListing[0].listing_images[1]" class="mw-100 zoomable-image" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="row">
                        <div class="col-12 col-md-12 text-center">
                          <button type="button" class="carousel-zoom">
                            <img :src="this.ogListing[0].listing_images[2]" class="mw-100 zoomable-image" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="row">
                        <div class="col-12 col-md-12 text-center">
                          <button type="button" class="carousel-zoom">
                            <img :src="this.ogListing[0].listing_images[3]" class="mw-100 zoomable-image" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="row">
                        <div class="col-12 col-md-12 text-center">
                          <button type="button" class="carousel-zoom">
                            <img :src="this.ogListing[0].listing_images[4]" class="mw-100 zoomable-image" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="row">
                        <div class="col-12 col-md-12 text-center">
                          <button type="button" class="carousel-zoom">
                            <img :src="this.ogListing[0].listing_images[5]" class="mw-100 zoomable-image" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              
              <!-- Right Button -->
              <!-- <button class="btn btn-primary bg-white filter-btn position-absolute top-50 translate-middle-y end-0 me-3" data-bs-target="#carouselTemplateThree" data-bs-slide="next" style="z-index: 10;">
                <i class="bi bi-arrow-right"></i>
              </button> -->
            </div>

            <div class="container py-5" v-html="ogListing[0].description"></div>

          </div>
        </div>
      </div>
    </div>

    </section>

    <Footer />
  
  </div>
</template>

<script>
import axios from 'axios';
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
  components: {
    Menu, Footer, ContactForm
  },
  data() {
    return {
      listing: { 
        data: {
          listing: [
            { on_show: false }
          ]
        }
      },
      ogListing: [
        {
          listing_images: []
        }
      ],
      agent: { },
      agent_2: { },
      agent_3: { },
      loading: false,
      template: 1,
      activeImageIndex: -1,
      isImageExpanded: false,
      isImageClicked: false,
      originalListings: null,
      listings: null,
      contactRequested: false,
      contactRequested_2: false,
      contactRequested_3: false,
      whatsappLink: '',
      whatsappLink_2: '',
      whatsappLink_3: '',
    };
  },
  mounted() {
    this.fetchListing();
  },
  computed: {
    formattedPrice() {
      const price = parseInt(this.ogListing[0].price);
      if (this.ogListing[0].poa == "true") {
        return "POA"
      } else {
        return "R " + price.toLocaleString();
      }
    },
    formattedDescription() {
      const rawDescription = this.listing.ai_long_description;
      const sentences = rawDescription.split('. ');
      const paragraphs = [];
      for (let i = 0; i < sentences.length; i += 3) {
        const paragraph = sentences.slice(i, i + 3).join('. ');
        if (!paragraph.endsWith('.')) {
          paragraphs.push(paragraph + '.');
        } else {
          paragraphs.push(paragraph);
        }
      }
      return paragraphs;
    },
    currentUrl() {
      return encodeURIComponent(window.location.href)
    },
    facebookShareLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${this.currentUrl}`
    },
    linkedinShareLink() {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.currentUrl}`
    },
    pinterestShareLink() {
      return `https://www.pinterest.com/pin/create/button/?url=${this.currentUrl}`;
    },
    twitterShareLink() {
      const text = encodeURIComponent("Luxury property presented by Chas Everitt Luxury Portfolio");
      return `https://twitter.com/intent/tweet?url=${this.currentUrl}&text=${text}`;
    },
  },
  methods: {
    printToPDF() {
      window.print();
    },
    fetchListing: async function () {
      this.loading = true
      try {
        const response = await axios.post('https://luxurywinelandsestates.co.za/api/listing-commercial.php', {
          id: this.$route.params.propertyId
        }, {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.ogListing = response.data.data.listing
        console.log(this.ogListing)
      } catch (error) {
        console.log(error)
      }
      
      try {
        const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
          id: this.ogListing[0].agent
        }, {
          headers: {
            'Accept': 'application/json',
          }
        })
        this.agent = agentResponse.data.data.agent[0]
      } catch (error) {
        console.log(error)
      }

      if (this.ogListing[0].agent_2 != null) {
        try {
          const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
            id: this.ogListing[0].agent_2
          }, {
            headers: {
              'Accept': 'application/json',
            }
          })
          this.agent_2 = agentResponse.data.data.agent[0]
        } catch (error) {
          console.log(error)
        }
      }

      if (this.ogListing[0].agent_3 != null) {
        try {
          const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
            id: this.ogListing[0].agent_3
          }, {
            headers: {
              'Accept': 'application/json',
            }
          })
          this.agent_3 = agentResponse.data.data.agent[0]
        } catch (error) {
          console.log(error)
        }
      }

      // Get the current URL
      let currentURL = window.location.href;
      let cleanedNumber = this.agent.cell_number.replace(/\D/g, '');
      let countryCode = '27';
      let formattedNumber = cleanedNumber.startsWith(countryCode) ? cleanedNumber : countryCode + cleanedNumber;
      this.whatsappLink = `https://wa.me/${formattedNumber}?text=Hello! I am interested in the property I found on your Portfolio website: ${currentURL}`;

      if (this.agent_2.cell_number) {
        cleanedNumber = this.agent_2.cell_number.replace(/\D/g, '');
        formattedNumber = cleanedNumber.startsWith(countryCode) ? cleanedNumber : countryCode + cleanedNumber;
        this.whatsappLink_2 = `https://wa.me/${formattedNumber}?text=Hello! I am interested in the property I found on your Portfolio website: ${currentURL}`;
      }

      if (this.agent_3.cell_number) {
        cleanedNumber = this.agent_3.cell_number.replace(/\D/g, '');
        formattedNumber = cleanedNumber.startsWith(countryCode) ? cleanedNumber : countryCode + cleanedNumber;
        this.whatsappLink_3 = `https://wa.me/${formattedNumber}?text=Hello! I am interested in the property I found on your Portfolio website: ${currentURL}`;
      }

      this.loading = false
    },
    expandImage(index) {
      this.activeImageIndex = index
      this.isImageExpanded = true
      this.isImageClicked = true
    }
  }
};
</script>

<style scoped>
.fixed-tags {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1000;
}
.expanded {
  transform: scale(1.2);
  z-index: 1;
}
.social-links{
  position: unset;
  display: inline;
  float: right;
  margin-right: 20px;
}
.force-height {
  height: 300px;
  object-fit: cover;
}
.carousel-control-next-icon {
  background-color: red;
  border-radius: 25px;
  font-size: 10px;
}
.carousel-control-prev-icon {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 25px;
  font-size: 10px;
}
</style>